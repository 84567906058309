(function () {
	'use strict';

	angular.module('app', ['ui.router', 'ngCookies'])
		.run(runBlock);

	runBlock.$inject = ['$rootScope', '$timeout', '$window'];
	function runBlock($rootScope, $timeout, $window) {
		window.addEventListener('load', function () {
			var observer = new MutationObserver(function (mutations) {
				mutations.forEach(function (mutation) {
					if (mutation.addedNodes)
						window.componentHandler.upgradeElements(mutation.addedNodes);
				})
			});
			observer.observe(document.body, {
				childList: true,
				subtree: true
			});
		});
	}
})();
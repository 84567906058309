(function() {
	'use strict';

	angular.module('app')
		.controller('aboutCtrl', aboutCtrl);

	aboutCtrl.$inject = ['$rootScope', '$scope', '$window'];
	function aboutCtrl($rootScope, $scope, $window) {
		var vm = this;
		$rootScope.activeLink = 'about';
	}
})();

(function () {
  'use strict';

  angular.module('app')
    .controller('specialEventCtrl', specialEventCtrl);

  specialEventCtrl.$inject = [];
  function specialEventCtrl() {

  }
})();
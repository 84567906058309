(function() {
	'use strict';
	angular.module('app')
		.service('upcomingEventsService', upcomingEventsService);

	upcomingEventsService.$inject = ['$http'];
	function upcomingEventsService($http) {
		return {
			getUpcomingEvents: getUpcomingEvents
		};

		function getUpcomingEvents() {
			return $http.get('https://wacochessclub.firebaseio.com/upcomingEvents.json')
				.then(getUpcomingEventsDone)
				.catch(getUpcomingEventsError);

			function getUpcomingEventsDone(response) {
				if (response && response.data) {
					angular.forEach(response.data, function(event) {
						event.date = new Date(event.dateString);
					});
					return response.data;
				} else {
					return [];
				}
			}

			function getUpcomingEventsError(error) {
				return [];
			}
		}
	}
})();

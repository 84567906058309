(function() {
	'use strict';

	angular.module('app')
		.controller('contactCtrl', contactCtrl);

	contactCtrl.$inject = ['$rootScope', 'contactService'];
	function contactCtrl($rootScope, contactService) {
		var vm = this;
		$rootScope.activeLink = 'contact';
		vm.contactForm = {};
		vm.contactForm.name = '';
		vm.contactForm.email = '';
		vm.contactForm.note = '';
		vm.message = '';

		vm.clearForm = clearForm;
		vm.submitForm = submitForm;
		vm.isSubmitReady = isSubmitReady;
		vm.updateMdlDirty = updateMdlDirty;

		function submitForm() {
			vm.message = 'Sending...';
			vm.messageStatus = '';
			contactService.submitForm(vm.contactForm)
				.then(function(wasSuccessful) {
					if (wasSuccessful) {
						vm.message = 'Message sent successfully';
						vm.messageStatus = 'good';
					} else {
						vm.message = 'Message failed to send, please try again later.';
						vm.messageStatus = 'bad';
					}
					
				});
		}

		function clearForm() {
			vm.contactForm.name = '';
			vm.contactForm.email = '';
			vm.contactForm.note = '';
			vm.message = '';
			updateMdlDirty('contact-name');
			updateMdlDirty('contact-email');
			updateMdlDirty('contact-note');
		}

		function isSubmitReady() {
			return vm.contactForm.name && vm.contactForm.name.length
				&& vm.contactForm.email && vm.contactForm.email.length
				&& vm.contactForm.note && vm.contactForm.note.length;
		}

		function updateMdlDirty(id) {
			var elem = document.getElementById(id);
			elem.MaterialTextfield.checkDirty();
		}
	}
})();

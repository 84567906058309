(function () {
	'use strict';

	angular.module('app')
		.config(configure);

	configure.$inject = ['$stateProvider', '$urlRouterProvider', '$locationProvider'];
	function configure($stateProvider, $urlRouterProvider, $locationProvider) {
		$stateProvider
			.state('app', {
				abstract: true,
				url: '/',
				views: {
					'header': {
						templateUrl: 'index.html'
					}
				}
			})
			.state('app.upcoming-events', {
				url: 'upcoming-events',
				views: {
					'main@': {
						templateUrl: 'upcoming-events/upcoming-events.html',
						controller: 'upcomingEventsCtrl as upcomingEvents'
					}
				}
			})
			.state('app.contact', {
				url: 'contact',
				views: {
					'main@': {
						templateUrl: 'contact/contact.html',
						controller: 'contactCtrl as contact'
					}
				}
			})
			.state('app.about', {
				url: 'about',
				views: {
					'main@': {
						templateUrl: 'about/about.html',
						controller: 'aboutCtrl as about'
					}
				}
			})
			.state('app.club-members', {
				url: 'club-members',
				views: {
					'main@': {
						templateUrl: 'club-members/club-members.html',
						controller: 'clubMembersCtrl as clubMembers'
					}
				}
			})
			.state('app.past-events', {
				url: 'past-events',
				views: {
					'main@': {
						templateUrl: 'past-events/past-events.html',
						controller: 'pastEventsCtrl as pastEvents'
					}
				}
			})
			.state('app.special-event', {
				url: 'special-event',
				views: {
					'main@': {
						templateUrl: 'special-event/special-event.html',
						controller: 'specialEventCtrl as specialEvent'
					}
				}
			})
			;
		$urlRouterProvider.otherwise('/upcoming-events');
	}
})();

(function () {
	'use strict';

	angular.module('app')
		.controller('upcomingEventsCtrl', upcomingEventsCtrl);

	upcomingEventsCtrl.$inject = ['$rootScope', '$timeout', '$cookies', '$cookieStore', 'upcomingEventsService'];
	function upcomingEventsCtrl($rootScope, $timeout, $cookies, $cookieStore, upcomingEventsService) {
		var vm = this;
		$rootScope.activeLink = 'upcomingEvents';
		vm.showChampionship = !$cookies.get('hideChampionship');
		vm.showDismissMessage = false;
		vm.today = new Date();
		vm.today.setHours(0, 0, 0, 0);
		vm.today = vm.today.getTime();
		vm.activeFlier = undefined;

		vm.dismissChampionship = dismissChampionship;
		vm.hideChampionshipCookie = hideChampionshipCookie;
		vm.toggleTournamentPopup = toggleTournamentPopup;
		vm.hideDialog = hideDialog;
		vm.upcomingEvents = [];
		upcomingEventsService.getUpcomingEvents().then(function (events) {
			vm.upcomingEvents = events;
		});
		// var dt = new Date(2022, 2, 26);
		// vm.upcomingEvents = [
		// 	{
		// 		"address" : "1624 Wooded Acres Dr, Waco, TX 76710",
		// 		"dateString" : "Feb 26, 2022 12:00:00 GMT-0500",
		// 		"date": dt,
		// 		"details" : "Open and U1400 sections, 4 Rounds of G/45;d5.  Must have active USChess rating.  $15EF cash only, prizes aanounced on site.",
		// 		"location" : "Holy Spirit Episcopal Church",
		// 		"name" : "Waco January Swiss 2022",
		// 		"roundTimes" : "Both sections - 4 Rounds, G/45;d5.  Registration 8:00-8:50am.  Round 1 at 9am.  Round 2 at 10:30am.  Round 3 at 1:00pm (after lunch).  Round 4 at 2:30pm.",
		// 		"sections" : "Two Sections -- Open and Reserve (U1400)",
		// 		"time" : "9am",
		// 		"type" : "tourney",
		// 		"useFlier" : true,
		// 		"useImage": true,
		// 		"imgLink": "images/Feb-Swiss-Reg.jpeg",
		// 		"registrationLink": "https://docs.google.com/forms/d/e/1FAIpQLSdPyGvCxsoc3QUXSZ6UXQSYEKnCayMdm9kY5uv4rCYQIqdbfA/viewform"

		// 	  }
		// ];

		function toggleTournamentPopup(event, type) {
			var dialog = document.querySelector('.' + type + '-popup.mdl-dialog');
			if (!dialog.showModal) {
				dialogPolyfill.registerDialog(dialog);
			}
			dialog.showModal();
		}

		function hideDialog(type) {
			var dialog = document.querySelector('.' + type + '-popup.mdl-dialog');
			if (!dialog.close) {
				dialogPolyfill.registerDialog(dialog);
			}
			vm.activeFlier = undefined;
			dialog.close();
		}

		function dismissChampionship() {
			vm.showChampionship = false;
			vm.showDismissMessage = true;
			$timeout(function () {
				vm.showDismissMessage = false;
			}, 3000);
		}

		function hideChampionshipCookie() {
			vm.showDismissMessage = false;
			$cookies.put('hideChampionship', '1');
		}
	}
})();

(function () {
	'use strict';
	angular.module('app')
		.controller('clubMembersCtrl', clubMembersCtrl);

	clubMembersCtrl.$inject = ['$rootScope', 'clubMembersService'];
	function clubMembersCtrl($rootScope, clubMembersService) {
		var vm = this;
		$rootScope.activeLink = 'clubMembers';
		clubMembersService.getMemberList().then(function (members) {
			vm.memberList = members;
			members.forEach(function (member) {
				var tmpDate = new Date(member.expiration);
				var today = new Date();
				today.setHours(0);
				today.setMinutes(0);
				today.setSeconds(0);
				member.expired = today.getTime() > tmpDate.getTime();
			});
		});
	}
})();

(function() {
	'use strict';

	angular.module('app')
		.controller('pastEventsCtrl', pastEventsCtrl);

	pastEventsCtrl.$inject = ['$rootScope', '$scope', '$window', '$timeout'];
	function pastEventsCtrl($rootScope, $scope, $window, $timeout) {
		var vm = this;
		$rootScope.activeLink = 'pastEvents';

		vm.showGame = {
			julySwiss2018: false,
			juneSwiss2018: false,
			maySwiss2018: false,
			champ2018: false,
			champQual2018: false,
			springSwiss2018: false,
			simul: false,
			winterSwiss2018: false,
			summerSwiss2017: false,
			springSwiss2017: false,
			winterSwiss2017: false,
			janesCup2017: false
		}

		var gameHolderWidth = document.getElementsByClassName('game-holder')[0].offsetWidth;
		vm.iframeWidth = gameHolderWidth;
		vm.iframeHeight = vm.iframeWidth * 0.61;

		var resizer = angular.element($window).bind('resize', function(){
			$timeout(function() {
				vm.iframeWidth = document.getElementsByClassName('game-holder')[0].offsetWidth;
				vm.iframeHeight = vm.iframeWidth * 0.61;
			});
		});

		$scope.$on('$destroy', function() {
			angular.element($window).unbind('resize');
		});
	}
})();

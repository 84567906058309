(function () {
	'use strict';
	angular.module('app')
		.service('clubMembersService', clubMembersService);

	clubMembersService.$inject = ['$http'];
	function clubMembersService($http) {
		return {
			getMemberList: getMemberList
		};

		function getMemberList() {
			return $http.get('https://wacochessclub.firebaseio.com/members.json')
				.then(getMemberListDone)
				.catch(getMemberListError);

			function getMemberListDone(response) {
				if (response && response.data) {
					angular.forEach(response.data, function (member) {
						member.expiration = new Date(member.expiration);
					});
					return response.data;
				} else {
					return [];
				}
			}

			function getMemberListError(error) {
				return [];
			}
		}
	}
})();

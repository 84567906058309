(function () {
	'use strict';

	angular.module('app')
		.service('contactService', contactService);

	contactService.$inject = ['$http'];
	function contactService($http) {
		return {
			submitForm: submitForm
		};

		function submitForm(contactForm) {
			var payload = {
				"attachments": [{
					"fallback": JSON.stringify(contactForm),
					"fields": [
						{
							"title": "Name",
							"value": contactForm.name,
							"short": false
						},
						{
							"title": "Email",
							"value": contactForm.email,
							"short": false
						},
						{
							"title": "Note",
							"value": contactForm.note,
							"short": false
						}
					]
				}]
			};
			return $http({
				url: 'https://hooks.slack.com/services/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',
				method: 'POST',
				data: payload, // Make sure to inject the service you choose to the controller
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded' // Note the appropriate header
				}
			})
				.then(submitFormSuccess)
				.catch(submitFormFailed);

			function submitFormSuccess(response) {
				return true;
			}

			function submitFormFailed(error) {
				return false;
			}
		}
	}
})();
